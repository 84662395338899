import axios from '@/libs/axios';
import { Inquiry } from './variable.js';

//询价列表
export const list = (data) => {
    return axios.request({
        url: Inquiry.list,
        data: data,
        method: 'post'
    });
};
//重新询价
export const update = (data) => {
    return axios.request({
        url: Inquiry.update,
        data: data,
        method: 'post'
    });
};
//询价删除
export const del = (data) => {
    return axios.request({
        url: Inquiry.del,
        data: data,
        method: 'post'
    });
};

//询价
export const inquiry = (data) => {
    return axios.request({
        url: Inquiry.inquiry,
        data: data,
        method: 'post'
    });
};